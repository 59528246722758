import { api, currentCreche } from '../api'

const ParentRepository = {
    fetchAll (crecheUuid = null) {
        return api().$get(`/${crecheUuid || currentCreche().uuid}/parents`)
    },

    export (query = {}) {
        return api().$get(`/${currentCreche().uuid}/parents/export`, query)
    },

    index (babyUuid, query = {}, crecheUuid = null) {
        return api().$get(`/${crecheUuid || currentCreche().uuid}/babies/${babyUuid}/parents`, query)
    },

    show (babyUuid, id) {
        return api().$get(`/${currentCreche().uuid}/babies/${babyUuid}/parents/${id}`)
    },

    showSingle (id) {
        return api().$get(`/${currentCreche().uuid}/parents/${id}`)
    },

    create (babyUuid, payload) {
        return api().$post(`/${currentCreche().uuid}/babies/${babyUuid}/parents`, payload)
    },

    update (babyUuid, parentUuid, payload) {
        return api().$put(`/${currentCreche().uuid}/babies/${babyUuid}/parents/${parentUuid}`, payload)
    },

    updateSingle (parentUuid, payload, crecheUuid = null) {
        return api().$put(`/${crecheUuid || currentCreche().uuid}/parents/${parentUuid}`, payload)
    },

    delete (babyUuid, parentUuid) {
        return api().$delete(`/${currentCreche().uuid}/babies/${babyUuid}/parents/${parentUuid}`)
    },

    sendCreateAccountRequest (babyUuid, parentUuid) {
        return api().$post(`/${currentCreche().uuid}/babies/${babyUuid}/parents/${parentUuid}/create-user`)
    },

    sendCreateAccountSingleRequest (parentUuid) {
        return api().$post(`/${currentCreche().uuid}/parents/${parentUuid}/create-user`)
    },

    requestAttachBaby (babyUuid, payload) {
        return api().$post(`/${currentCreche().uuid}/babies/${babyUuid}/parents/attach`, payload)
    },

    resendPassword (parentUuid) {
        return api().$post(`/${currentCreche().uuid}/parents/${parentUuid}/resend-confirmation`)
    },

    resendRequestAttachBaby (babyUuid, parentUuid) {
        return api().$post(`/${currentCreche().uuid}/babies/${babyUuid}/parents/${parentUuid}/resend-baby-confirmation`)
    },

    getMails (parentUuid) {
        return api().$get(`/${currentCreche().uuid}/parents/${parentUuid}/mails`)
    },

    getBalance (parentUuid) {
        return api().$get(`/${currentCreche().uuid}/parents/${parentUuid}/balance`)
    },

    detachUser (parentUuid) {
        return api().$post(`/${currentCreche().uuid}/parents/${parentUuid}/detach-user`)
    }
}

export default ParentRepository
